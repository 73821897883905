import { LocalStorage } from 'core/decorators';

import { EMBED_REACTIONS_LS_KEY, STORAGE_MAX_SIZE } from './constants';


function getReactionsFromLS() {
  return JSON.parse(LocalStorage.getItem(EMBED_REACTIONS_LS_KEY)) || [];
}

export function getReactionFromLS(id) {
  const reactions = getReactionsFromLS();
  return reactions.find(({ id: localId }) => localId === id);
}

export function addReactionToLS(id, value) {
  const reactionsLS = getReactionsFromLS();

  /**
   * Ограничиваем максимальный размер стораджа по размеру.
   */
  if (reactionsLS.length >= STORAGE_MAX_SIZE) {
    reactionsLS.shift();
  }

  LocalStorage.setItem(
    EMBED_REACTIONS_LS_KEY,
    JSON.stringify(reactionsLS.concat({ id, value }))
  );
}
